import React from "react";
import { Container } from "react-bootstrap";

import { pasteventsCompData } from "../data/components-pastevents.data";

import SectionHeader from "./SectionHeader";
import LocationIcon from "../images/location-new.svg";
import CalendarIcon from "../images/calendar.svg";
import { getDateRangeString } from "../helpers/formatHelpers";

const PastEvents = () => {
  const compData = pasteventsCompData();

  const events = compData.allWpPost.nodes
    .map((post) => {
      return {
        id: post.id,
        title: post.title,
        dateStart:
          post.eventPost && post.eventPost.dateStart
            ? post.eventPost.dateStart
            : post.date,
        dateEnd:
          post.eventPost && post.eventPost.dateEnd
            ? post.eventPost.dateEnd
            : "",
        place:
          post.eventPost && post.eventPost.place ? post.eventPost.place : "",
      };
    })
    .filter((post) => {
      const currentDate = new Date();

      if (post && post.dateEnd) {
        const dateEndTs = new Date(post.dateEnd);
        return dateEndTs < currentDate;
      } else {
        const dateStartTs = new Date(post.dateStart);
        return dateStartTs < currentDate;
      }
    })
    .sort((a, b) => {
      const bDateTs = new Date(b.dateStart);
      const aDateTs = new Date(a.dateStart);

      return bDateTs - aDateTs;
    })
    .slice(0, 3);

  return (
    <Container className="news-and-insights">
      <SectionHeader header={"Past Events"} />

      <div className="event-tile__container">
        {events.map((event) => (
          <div key={event.id} className="event-tile">
            <h4>{event.title}</h4>

            <div className="event-tile__content">
              <div className="event-tile__icon">
                <LocationIcon />
              </div>
              <div className="event-tile__text">{event.place}</div>

              <div className="event-tile__icon">
                <CalendarIcon />
              </div>
              <div className="event-tile__text">
                {getDateRangeString(event.dateStart, event.dateEnd, true)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default PastEvents;
