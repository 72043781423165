import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const pasteventsCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPost(
          filter: {
            categories: {
              nodes: {
                elemMatch: { wpParent: { node: { slug: { eq: "events" } } } }
              }
            }
          }
        ) {
          nodes {
            id
            title
            date
            eventPost {
              dateEnd
              dateStart
              place
            }
          }
        }
      }
    `
  );

  return data;
};

export { pasteventsCompData };
