import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const newsinsCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        # Section text
        wp {
          mdaThemeOptions {
            themeSettings {
              newsinsLabel
              newsinsHeader
              newsinsText
              newsinsLink {
                target
                title
                url
              }
            }
          }
        }

        # Posts list
        allWpPost(
          limit: 3
          sort: { fields: date, order: DESC }
          filter: {categories: {nodes: {elemMatch: {wpParent: {node: {slug: {eq: "news"}}}}}}}
        ) {
          nodes {
            id
            title
            date(formatString: "DD MMMM YYYY")
            categories {
              nodes {
                name
                slug
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
          }
        }
        allSitePage(filter: { context: { type: { eq: "post" } } }) {
          nodes {
            path
            context {
              id
            }
          }
        }
      }
    `
  );

  return data;
};

export { newsinsCompData };
