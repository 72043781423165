import React from "react";
import ButtonText from "../components/ButtonText";

export const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

export const getDateRangeString = (dateStart, dateEnd, shortMonth) => {
  const startDateDay = dateStart.split(" ")[0];
  const endDateDay = dateEnd?.split(" ")[0];

  const startDateMonth = dateStart
    .split(" ")[1]
    ?.slice(0, shortMonth ? 3 : undefined);
  const endDateMonth = dateEnd
    ?.split(" ")[1]
    ?.slice(0, shortMonth ? 3 : undefined);

  const startDateYear = dateStart.split(" ")[2];
  const endDateYear = dateEnd?.split(" ")[2];

  if (dateEnd && dateEnd != "") {
    if (startDateYear === endDateYear) {
      if (startDateDay == endDateDay) {
        return `${startDateMonth} ${startDateDay}, ${startDateYear}`;
      } else {
        return `${startDateMonth} ${startDateDay} - ${endDateMonth} ${endDateDay}, ${endDateYear}`;
      }
    } else {
      return `${startDateMonth} ${startDateDay},${startDateYear} - ${endDateMonth} ${endDateDay}, ${endDateYear}`;
    }
  } else {
    return `${startDateMonth} ${startDateDay}, ${startDateYear}`;
  }
};

export const getPlaceString = (arg1, arg2) => {
  const place = arg1;
  const category = arg2;

  if (place && place != "") {
    return `${place}, ${category}`;
  } else {
    return `${category}`;
  }
};

export const getContactString = (contact) => {
  if (contact && contact != "") {
    return (
      <p className="articles-box__contact">
        Connect with us at
        <ButtonText variant="in-text" {...contact}>
          {contact?.title}
        </ButtonText>
      </p>
    );
  }
};
