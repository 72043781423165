// Default imports
import React, { useEffect, useState } from "react";
import Link from "./Link";
import { useQueryParam, StringParam, NumberParam } from "use-query-params";
import { Row, Col } from "react-bootstrap";

import { intelligenceCompData } from "../data/components-intelligence.data";
import { TabLabels } from "./Tabs";
import ButtonText from "./ButtonText";
import Calendar from "../images/calendar.svg";
import News from "../images/news.svg";
import {
  getDateRangeString,
  getPlaceString,
  getContactString,
} from "../helpers/formatHelpers";

const ArticlesEvents = ({
  showPostsCountInitial = 5,
  loadCount = 5,
  temp = "news",
}) => {
  const compData = intelligenceCompData();

  const [activeParentCat = temp, setActiveParentCat] = useQueryParam(
    "parentcat",
    StringParam
  );

  const [activeCat, setActiveCat] = useQueryParam("cat", StringParam);

  /**
   * TABS
   *
   * Spread all the categories with new kets mapping
   */
  const parentTabs = compData.allWpCategory.nodes
    .filter((node) => node.parentId === null)
    .map((node) => {
      return { name: node.name, id: node.slug };
    });

  const tabs = [
    { name: "all" },
    ...compData.allWpCategory.nodes
      .filter((node) => {
        return (
          node.count > 0 &&
          node.wpParent &&
          node.wpParent.node.slug === activeParentCat
        );
      })
      .filter((node) => {
        return node;
      })
      .map((node) => {
        return {
          ...node,
          name: node.name,
          id: node.slug,
          order: Number(node.description) || 0,
        };
      })
      .sort((a, b) => a.order - b.order),
  ];

  const articles = compData.allWpPost.nodes
    .filter((post) => {
      return compData.allSitePage.nodes.find(
        (sitePage) => sitePage.context.id === post.id
      );
    })
    .map((post) => {
      const localPage = compData.allSitePage.nodes.find(
        (sitePage) => sitePage.context.id === post.id
      );
      return {
        key: post.id,
        category: post.categories.nodes[0].name,
        dateStart:
          post.eventPost && post.eventPost.dateStart
            ? post.eventPost.dateStart
            : post.date,
        dateEnd:
          post.eventPost && post.eventPost.dateEnd
            ? post.eventPost.dateEnd
            : "",
        place:
          post.eventPost && post.eventPost.place ? post.eventPost.place : "",
        contact:
          post.eventPost && post.eventPost.contact
            ? post.eventPost.contact
            : "",
        title: post.title,
        text: post.content,
        cat: post.categories.nodes[0].slug,
        parentCat: post.categories.nodes[0].wpParent.node.slug,
        url: localPage.path,
      };
    })
    .filter((post) => {
      const currentDate = new Date();

      if (post && post.dateEnd) {
        const dateEndTs = new Date(post.dateEnd);
        return dateEndTs > currentDate;
      } else {
        const dateStartTs = new Date(post.dateStart);
        return dateStartTs > currentDate;
      }
    })
    .sort((a, b) => {
      const bDateTs = new Date(b.dateStart);
      const aDateTs = new Date(a.dateStart);

      return aDateTs - bDateTs;
    });

  const [showPostsCount, setShowPostsCount] = useQueryParam(
    "showPostsCount",
    NumberParam
  );
  const [preventScroll, setPreventScroll] = useQueryParam("prsc", StringParam);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  });

  const articlesFiltered = articles.filter(
    (article) =>
      (!activeCat || article.cat === activeCat) &&
      article.parentCat === activeParentCat
  );

  const articlesCurrent = articlesFiltered.slice(
    0,
    showPostsCount || showPostsCountInitial
  );

  const handleTabClick = ({ id }) => {
    setShowPostsCount();
    setActiveCat(id);
  };

  const handleLoadMore = () => {
    setShowPostsCount(articlesCurrent.length + loadCount);
    setPreventScroll(true);
  };

  const handleParentTabClick = ({ event, id }) => {
    event.preventDefault();
    setPreventScroll(true);

    setActiveParentCat(id);
    setActiveCat();
  };

  const usedCategories = [...new Set(articles.map((article) => article.cat))];

  const tabFiltered = tabs.filter((tab) => usedCategories.includes(tab.id));

  tabFiltered.unshift(tabs[0]);

  return (
    <div>
      {/* <TabLabels
        className="pb-3"
        buttonVariant="small,deepblue,underline"
        labels={parentTabs.map((item) => ({
          label: item.name,
          active: item.id === activeParentCat,
          onClick: (e) => handleParentTabClick({ event: e, id: item.id }),
        }))}
      /> */}

      {/* Tabs list */}
      <TabLabels
        className="py-2"
        buttonVariant="small,deepblue"
        labels={tabFiltered.map((item) => ({
          label: item.name,
          active: item.id === activeCat,
          onClick: (e) => handleTabClick({ event: e, id: item.id }),
        }))}
      />

      {/* Articles */}
      <Row className="pt-5">
        <Col lg="16">
          <div className="articles">
            {articlesCurrent.map((article, index) => (
              <Article
                key={article.id}
                className={
                  isLoaded && (!activeCat || activeCat === article.cat)
                    ? ""
                    : "d-none"
                }
                style={{ animationDelay: `${index < 10 ? index * 0.1 : 1}s` }}
                {...article}
              />
            ))}
            {isLoaded && articlesFiltered.length > articlesCurrent.length && (
              <ButtonText variant="slim,red" onClick={handleLoadMore}>
                Load more
              </ButtonText>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Article = ({
  className,
  category,
  dateStart,
  dateEnd,
  place,
  title,
  text,
  url,
  parentCat,
  eventDate,
  contact,
  ...attrs
}) => (
  <div className={`articles-box ${className}`} {...attrs}>
    <h4 className="articles-box__title">{title}</h4>
    <ul className="articles-box__list">
      <li className="articles-box__list-item">
        {parentCat && parentCat == "events" ? (
          <Calendar className="articles-box__calendar-icon" />
        ) : (
          <News className="articles-box__calendar-icon" />
        )}
        {getDateRangeString(dateStart, dateEnd)}
      </li>
      <li className="articles-box__list-item">
        {getPlaceString(place, category)}
      </li>
    </ul>
    <div
      className="articles-box__text"
      dangerouslySetInnerHTML={{ __html: text }}
    />
    {getContactString(contact)}
  </div>
);

export default ArticlesEvents;
