// Default imports
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useQueryParam, StringParam } from "use-query-params";

import ButtonText from "../components/ButtonText";
import { ListSpecialContentItem } from "./ListSpecial";

const Tabs = ({ tabs }) => {
  const [preventScroll, setPreventScroll] = useQueryParam("prsc", StringParam);

  const [activeTabId, setActiveTabId] = useState(0);
  const [activeContentItem, setActiveContentItem] = useState(null);

  const activeTab = tabs?.[activeTabId];

  const handleTabClick = ({ event, id }) => {
    event.preventDefault();
    setPreventScroll(true);

    setActiveTabId(id);
    setActiveContentItem(null);
  };

  const handleContentItemClick = ({ event, id }) => {
    event.preventDefault();
    setPreventScroll(true);

    setActiveContentItem(id === activeContentItem ? null : id);
  };

  return (
    <>
      <TabLabels
        buttonVariant="small,deepblue"
        labels={tabs.map((tab, i) => ({
          label: tab.label,
          active: i === activeTabId,
          onClick: (e) => handleTabClick({ event: e, id: i }),
        }))}
      />
      <Row className="tabs__content">
        {activeTab?.content?.map((item, i) => (
          <ListSpecialContentItem
            key={i}
            onClick={(e) => {
              handleContentItemClick({ event: e, id: i });
            }}
            folded={i !== activeContentItem}
            {...item}
          />
        ))}
      </Row>
    </>
  );
};

const TabLabels = ({ labels, buttonVariant, className, ...props }) => (
  <Row className={`g-0 tabs__labels ${className || ""}`} {...props}>
    {labels?.map((item, i) => (
      <ButtonText
        key={i}
        variant={buttonVariant}
        className={`tabs__label ${item.active && "-active"}`}
        onClick={item.onClick}
      >
        {item.label}
      </ButtonText>
    ))}
  </Row>
);

export default Tabs;
export { TabLabels };
