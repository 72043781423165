import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const intelligenceCompData = () => {
  const data = useStaticQuery(
    graphql`
      {
        allSitePage(filter: { context: { type: { eq: "post" } } }) {
          nodes {
            path
            context {
              id
            }
          }
        }

        allWpCategory {
          nodes {
            databaseId
            id
            name
            description
            slug
            count
            parentId
            wpParent {
              node {
                slug
              }
            }
          }
        }

        allWpPost(sort: { fields: date, order: DESC }) {
          nodes {
            id
            title
            date(formatString: "DD MMMM YYYY")
            excerpt
            content
            eventPost {
              dateStart
              dateEnd
              place
              contact {
                target
                title
                url
              }
            }
            categories {
              nodes {
                name
                slug
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export { intelligenceCompData };
