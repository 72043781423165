// Default imports
import React from "react";
import Link from "./Link";
import { Row, Col, Container } from "react-bootstrap";

import { newsinsCompData } from "../data/components-newsandinsights.data";

import SectionHeader from "./SectionHeader";
import ButtonText from "./ButtonText";

const NewsAndInsights = () => {
  const compData = newsinsCompData();

  // Articles
  const articles = compData.allWpPost.nodes
    .filter((post) => {
      return compData.allSitePage.nodes.find(
        (sitePage) => sitePage.context.id === post.id
      );
    })
    .map((post) => {
      const localPage = compData.allSitePage.nodes.find(
        (sitePage) => sitePage.context.id === post.id
      );
      return {
        id: post.id,
        category: post.categories.nodes[0].name,
        date: post.date,
        title: post.title,
        url: localPage.path,
      };
    });

  // Section data
  const {
    newsinsHeader: header,
    newsinsLabel: label,
    newsinsText: text,
    newsinsLink: link,
  } = compData.wp.mdaThemeOptions.themeSettings;

  return (
    <Container className="news-and-insights">
      <Row>
        <Col className="news-and-insights__desc" xs="24" lg="10">
          <div className="news-and-insights__headline">
            <SectionHeader header={header} />
          </div>

          <p className="news-and-insights__text">{text}</p>

          {link && (
            <ButtonText
              {...link}
              className="news-and-insights__button d-none d-sm-flex"
              variant="red"
            >
              {link?.title}
            </ButtonText>
          )}
        </Col>
        <Col xs="24" lg="14">
          {articles.map((article, index) => (
            <NewsAndInsightsItem key={index} {...article} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

const NewsAndInsightsItem = ({ category, date, title, url, ...props }) => (
  <div className="news-and-insights__item" {...props}>
    <div className="news-and-insights__item-top">
      <div className="news-and-insights__category">{category}</div>
      <span>|</span>
      <div className="news-and-insights__date">{date}</div>
    </div>

    {url && (
      <Link to={url} className="news-and-insights__link">
        {title}
      </Link>
    )}
  </div>
);

export default NewsAndInsights;
